import React, { useEffect, useState } from 'react';
import SetCookie from "../hooks/setCookie"
import GetCookie from "../hooks/GetCookie"
import RemoveCookie from "../hooks/RemoveCookie"
import axios from 'axios';
import { LOGIN_URL } from '../api/http';

const Login = ({ onLogin }) => {
  const [id, setid] = useState("")
  const [password, setPassword] = useState('');

  useEffect(() => {
    if(GetCookie("auth_user")) {
      const cookie = JSON.parse(GetCookie("auth_user"))
      onLogin(cookie.user)
    }
  }, [onLogin])
  

  const handleIdChange = (e) => {
    setid(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    const formData = new FormData();
    formData.append("id", id)
    formData.append("password", password)
    axios.post(LOGIN_URL,formData,{
      headers: { 'Content-Type': 'application/json'}
    }).then(res=> {
      RemoveCookie("auth_user");
      SetCookie("auth_user", JSON.stringify({token: res.data.token, user: res.data.user}))
      onLogin(res.data.user)
    }).catch(console.error)
  };

  return (
    <div className='Login'>
      <h2>Login</h2>
      <div>
        <input type="text" placeholder="ID" value={id} onChange={handleIdChange} />
      </div>
      <div>
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <button onClick={handleLogin}>Anmelden</button>
    </div>
  );
};

export default Login;
