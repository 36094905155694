import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import LoadingFullScreen from "./LoadingFullScreen";
import { UPLOAD_IMAGE } from "../api/http";

const ReceiptUpload = ({ user, setrefresh }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const handleImageChange = (imageList) => {
    console.log(imageList);
    setImages(imageList);
  };

  const handleUpload = () => {
    if (!isValid()) {
      alert("Select an Image");
    }
    setIsLoading(true);
    // Erstelle ein FormData-Objekt, um die Datei und die Beschreibung zu senden
    //const cookie = JSON.parse(GetCookie("auth_user"))
    const formData = new FormData();
    var image = images[0]; /* .forEach((image) => {
      formData.append("images", image.file);
    }); */
    formData.append("file", image.file);
    formData.append("_id", user?._id);

    // Sende die Datei und die Beschreibung an den Server
    fetch(UPLOAD_IMAGE, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Server-Antwort:", data);
        setImages([]);
        setrefresh((prev) => !prev);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Fehler beim Hochladen:", error);
        alert("Fehler beim Hochladen (123)");
        setIsLoading(false);
        // Hier kannst du Fehlerbehandlung implementieren.
      });

    // Zurücksetzen der Eingabefelder nach dem Hochladen:
  };

  const isValid = () => {
    return images.length > 0;
  };

  return (
      <div>
        {isLoading && <LoadingFullScreen />}
        <h2>Quittung hochladen</h2>

        <ImageUploading value={images} onChange={handleImageChange}>
          {({ imageList, onImageUpload, onImageRemoveAll }) => (
            <div>
              <button onClick={onImageUpload}>Bilder auswählen</button>
              <button onClick={onImageRemoveAll}>Alle entfernen</button>
              {imageList.map((image, index) => {
                return (
                  <div key={index}>
                    <img src={image.dataURL} alt="" width="100" />
                  </div>
                );
              })}
            </div>
          )}
        </ImageUploading>
        <button onClick={handleUpload} disabled={!isValid()}>
          Hochladen {isValid()}
        </button>
      </div>
  );
};

export default ReceiptUpload;
