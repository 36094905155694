
export const IP = "https://quittungsapp.de"
//export const IP = "http://192.168.178.22:5000" 
export const VERSION = "/api/"

export const UPLOAD_IMAGE = IP + VERSION + "upload"

export const ADD_USER = IP + VERSION + "adduser"

export const LOGIN_URL = IP + VERSION + "login"

export const OPEN_RECEIPT = IP + VERSION + "openreceipt/"

export const SET_UN_OPEN_RECEIPT = IP + VERSION + "setunopenreceipt/"

export const GET_ALL_RECEIPTS = IP + VERSION + "receipts/"

export const GET_ALL_USERS = IP + VERSION + "users/all"

export const DELETE_RECEIPT = IP + VERSION + "receipts"

export const GET_IMAGE_URL = IP + VERSION + "uploads/"
