import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingFullScreen from "./LoadingFullScreen";
import { DELETE_RECEIPT, GET_ALL_RECEIPTS, GET_ALL_USERS, GET_IMAGE_URL, OPEN_RECEIPT, SET_UN_OPEN_RECEIPT } from "../api/http";

const ReceiptList = ({ user, setrefresh, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [totalReceipts, setTotalReceipts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4; // Number of receipts per page
  const [selectedFoto, setSelectedFoto] = useState(null);
  const [scale, setScale] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [users, setusers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [select_mode, setselect_mode] = useState(false);
  const [selected_foto_list, setselected_foto_list] = useState([]);

  
  const [selectedUser, setSelectedUser] = useState("");

  const openModal = (foto) => {
    axios
      .get(
        OPEN_RECEIPT +
          user._id +
          "/" +
          foto._id
      )
      .catch(console.error);
    setSelectedFoto(foto);
  };

  const setUnopend = (foto) => {
    axios
      .get(
        SET_UN_OPEN_RECEIPT +
          user._id +
          "/" +
          foto._id
      )
      .then((res) => {
        setrefresh((prev) => !prev);
      })
      .catch(console.error);
  };

  const closeModal = () => {
    setSelectedFoto(null);
    setrefresh((prev) => !prev);
    setOffsetX(0);
    setOffsetY(0);
  };

  const fetchReceipts = async (page) => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${GET_ALL_RECEIPTS}${user._id}?page=${page}&pageSize=${pageSize}&user=${selectedUser}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          /* headers: {
          Authorization: 'Bearer your_access_token', // Replace with actual token
        }, */
        }
      );
      setIsLoading(false)

      const data = await response.json();
      const { receipts: newReceipts, totalReceipts: newTotalReceipts } = data;

      setReceipts(groupReceiptsByUser(newReceipts));
      setTotalReceipts(newTotalReceipts);
    } catch (error) {
      console.error("Error fetching receipts:", error);
      setIsLoading(false)
      alert("Error fetching receipts:")
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    fetchReceipts(currentPage);
  }, [refresh, user, currentPage, selectedUser, startDate, endDate]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        GET_ALL_USERS +"?id=" + user._id,
        {
          method: "GET",
        }
      );

      const users_array = await response.json();

      // Handle the retrieved users in your component
      setusers(users_array);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    fetchUsers();
  }, [user]);

  const groupReceiptsByUser = (receipts) => {
    const groupedReceipts = {};
    receipts.forEach((receipt) => {
      if (groupedReceipts[receipt.user._id]) {
        groupedReceipts[receipt.user._id].push(receipt);
      } else {
        groupedReceipts[receipt.user._id] = [receipt];
      }
    });
    return groupedReceipts;
  };

  const getUnOpendReceipts = (list) => {
    return list.filter((item) => {
      return !item.openedBy.includes(user._id);
    }).length;
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const newScale = Math.min(10, Math.max(1, scale + e.deltaY * -0.01));
    setScale(newScale);
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      setOffsetX(offsetX + e.movementX);
      setOffsetY(offsetY + e.movementY);
    }
  };

  const toggleFotoSelection = ( receipt) => {
    if (selected_foto_list.includes(receipt)) {
      setselected_foto_list(
        selected_foto_list.filter((selected) => selected !== receipt)
      );
    } else {
      setselected_foto_list([...selected_foto_list, receipt]);
    }
    console.log(selected_foto_list, receipt);
  };

  const handleRemove = async () => {
    if (selected_foto_list.length > 0) {
      setIsLoading(true)
      try {
        const response = await fetch(
          DELETE_RECEIPT,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              //Authorization: 'Bearer your_access_token', // Replace with actual token
            },
            body: JSON.stringify({
              receiptIds: selected_foto_list.map((item) => item._id),
            }),
          }
        );

        if (response.ok) {
          console.log("Receipts removed successfully");
          setselected_foto_list([]);
          setselect_mode(false);
          setrefresh((prev) => !prev);
          setIsLoading(false)
          // Update your receipt list or perform other actions as needed
        } else {
          console.error("Error removing receipts");
          setIsLoading(false)
          alert("Error removing receipts")
        }
      } catch (error) {
        console.error("Error deleting receipts:", error);
        setIsLoading(false)
        alert("Error deleting receipts:")
      }
    }
  };

  return (
    <div>
        {isLoading && <LoadingFullScreen />}
    <div className="receiptlist-content">
      {/* <h2>Quittungen {user.name}</h2> */}
      <div className="receiptlist-content-filter">
        <div className="receiptlist-content-filter-date">
          <div style={{fontWeight: "bold"}}>Datum Filter</div>
          <div className="date-picker">
            <input
              type="date"
              className="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <span className="date-range-separator">-</span>
            <input
              type="date"
              className="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <div className="receiptlist-content-filter-user">
          <div class="user-select">
            <label for="user-list">Wähle einen Benutzer:</label>
            <select
              id="user-list"
              className="select-box"
              value={selectedUser}
              onChange={(e) => {
                setCurrentPage(1);
                setSelectedUser(e.target.value);
              }}
            >
              <option value="">All Users</option>
              {users.map((user) => {
                return (
                  <option
                    className={
                      user.receipts.length > 0 ? "user-option-selected" : ""
                    }
                    key={user.user._id}
                    value={user.user._id}
                  >
                    {user.user.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="receiptlist-content-remove">
        <div>
          <button
            onClick={() => {
              setselected_foto_list([]);
              setselect_mode((prev) => !prev);
            }}
          >
            {select_mode ? "Quittungen auswählen" : "Quittung löschen"}
          </button>
          {selected_foto_list.length > 0 && (
            <button onClick={handleRemove}>löschen</button>
          )}
        </div>
      </div>

      <div className="paging">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous Page
        </button>
        <button
          disabled={currentPage * pageSize >= totalReceipts}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next Page
        </button>
      </div>
      {Object.entries(receipts).map(([userId, userReceipts]) => {
        return (
          <div key={userId}>
            <h3>
              {userReceipts[0].user.name} - {getUnOpendReceipts(userReceipts)}
            </h3>
            <div className="foto-liste-container">
              <div className="foto-liste">
                {userReceipts.map((receipt, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={(() => {
                          if (select_mode) {
                            if (receipt.openedBy.includes(user._id)) {
                              return selected_foto_list.includes(receipt)
                                ? "foto-item event_selected"
                                : "foto-item";
                            } else {
                              return selected_foto_list.includes(receipt)
                                ? "foto-item event_selected"
                                : "foto-item selected";
                            }
                          } else {
                            return receipt.openedBy.includes(user._id)
                              ? "foto-item"
                              : "foto-item selected";
                          }
                        })()}
                        onClick={() => {
                          if (select_mode) {
                            toggleFotoSelection(receipt);
                          } else {
                            openModal(receipt);
                          }
                        }}
                      >
                        <img
                          src={`${GET_IMAGE_URL}${receipt.filename}`}
                          alt={`Foto ${index + 1}`}
                        />
                        <p className="foto-date">
                          {new Date(receipt.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                      {receipt.openedBy.includes(user._id) && (
                        <>
                          <button
                            onClick={() => setUnopend(receipt)}
                            style={{ width: "140px" }}
                          >
                            Als ungesehen markieren
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              {selectedFoto && (
                <div className="modal">
                  <span className="close-btn" onClick={closeModal}>
                    &times;
                  </span>
                  <img
                    style={{
                      width: "auto",
                      height: "auto",
                      transform: `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`,
                    }}
                    onWheel={handleWheel}
                    onMouseMove={handleMouseMove}
                    src={`${GET_IMAGE_URL}${selectedFoto.filename}`}
                    alt="Vollbildfoto"
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className="paging">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous Page
        </button>
        <button
          disabled={currentPage * pageSize >= totalReceipts}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next Page
        </button>
      </div>
    </div>
    </div>
  );
};

export default ReceiptList;
