import React, { useState } from "react";
import "./App.css";
import ReceiptUpload from "./components/ReceiptUpload";
import ReceiptList from "./components/ReceiptList";
import Login from "./components/Login";
import RemoveCookie from "./hooks/RemoveCookie";
import AddUser from "./components/AddUser";
import logo from './images/logo.png'; // Importiere das Logo-Bild
import { RiLogoutBoxLine } from 'react-icons/ri';
import {BsPersonPlus} from "react-icons/bs"

const App = () => {
  // Zustand für die Benutzer-Authentifizierung
  const [user, setUser] = useState(null);
  const [mode, setmode] = useState("home-page");
  const [refresh, setrefresh] = useState(true);

  // Funktion zum Setzen des angemeldeten Benutzers
  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
    RemoveCookie("auth_user");
  };

  const addUser = () => {
    setmode("add-user");
  };

  

  return (
    <div className="App">
      <header className="App-header">
      <img className="header-logo" src={logo} alt="Logo" />
        {user ? (
          <div className="header-login-wrapper">
            <BsPersonPlus className="adduser-btn" size={30}  onClick={addUser}/>
            <RiLogoutBoxLine className="logout-btn" size={30} onClick={handleLogout}/>
            <div>{user.name}</div>
          </div>
        ) : (
          <p>Bitte melden Sie sich an, um die Quittungen zu sehen.</p>
        )}
      </header>
      <main>
        {user && user._id ? (
          <>
            {mode === "add-user" ? (
              <>
                <AddUser setMode={setmode} />
              </>
            ) : (
              <>
                <ReceiptUpload user={user} setrefresh={setrefresh} />
                <ReceiptList
                  user={user}
                  setrefresh={setrefresh}
                  refresh={refresh}
                />
              </>
            )}
          </>
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </main>
      <footer className="App-footer">
        <p>© {new Date().getFullYear()} Quittungs-App</p>
      </footer>
    </div>
  );
};

export default App;
