import axios from "axios";
import React, { useState } from "react";
import GetCookie from "../hooks/GetCookie";
import { ADD_USER } from "../api/http";

const AddUser = ({ setMode }) => {
  const [id, setid] = useState("");
  const [password, setPassword] = useState("");
  const [name, setname] = useState("");
  const [rolle, setrolle] = useState("mitarbeiter")

  const handleIdChange = (e) => {
    setid(e.target.value);
  };

  const handleRoleChange = (e) => {
    setrolle(e.target.value);
  };

  const handleNameChange = (e) => {
    setname(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handelSignup = () => {
    const formData = new FormData();
    formData.append("id", id)
    formData.append("password", password)
    formData.append("name", name)
    formData.append("rolle", rolle)
    const cookie = JSON.parse(GetCookie("auth_user"))
    axios.post(ADD_USER,formData,{
      headers: { 'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.token}`}
    }).then(res=> {
        setMode("");
    }).catch(console.error)
  };

  const handelAbbrechen = () => {
    setMode("");
  };
  return (
    <div>
      <h2>Add User</h2>
      <div>
        <input
          type="text"
          placeholder="ID"
          value={id}
          onChange={handleIdChange}
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={handleNameChange}
        />
      </div>
      <div>
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <div>
        <label>
          Role:
          <select value={rolle} onChange={handleRoleChange}>
            <option value="mitarbeiter">Mitarbeiter</option>
            <option value="admin">Admin</option>
          </select>
        </label>
      </div>
      <button onClick={handelAbbrechen}>Abbrechen</button>
      <button onClick={handelSignup}>Hinzufügen</button>
    </div>
  );
};

export default AddUser;
