import Cookie from "js-cookie"


const SetCookie = (cookiename, user) => {
     Cookie.set(cookiename, user, {
        expires: new Date(Date.now() + 2000 * 1000), // 2000 second
        secure: true,
        sameSite: "strict",
        path: "/"
    })
}
export default SetCookie